import * as React from "react"
import Seo from "../components/seo"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import ClientCard from "../components/ClientCard"
import LogoDiamond from "../components/LogoDiamond"
import {AnchorLink} from "gatsby-plugin-anchor-links"

const WorkPage = () => (

  <Layout>
      <Seo title="Work" />

    <Helmet>
    <body className="body-dark"></body>
    </Helmet>    

    <section className="px-4 pt-16 pb-16 mt-32 text-owlwhite">

      <div className="container">
      <h1 className="text-owlwhite display-2 text-6xl text-left mb-16 border-b pb-4">Work</h1>

        <div className="grid md:grid-cols-3 gap-8">

            <div className="md:col-span-2">
                <ClientCard 
                  class="hendersons"
                  client="work-hendersons"
                  clientname="Hendersons"
                  bg="#cdcdcd"
                />  
            </div>
            <div className="h-full md:row-span-2">
                <ClientCard 
                  class="together"
                  client="work-together"
                  clientname="Together Compliance"
                  bg="#EAD8B0"
                /> 
            </div>
            <div>
                <ClientCard 
                  class="homera"                
                  client="work-homera"
                  clientname="Homera"
                  bg="#FCDCCB"
                />         
            </div>
            <div>
                <ClientCard 
                  class="just"                
                  client="work-just"
                  clientname="Just"
                  bg="#FE5F55"
                  textColor="text-gray-700"
                />    
            </div>         

        </div>

        <div className="w-6 h-8 mt-16 animate-bounce">
          <AnchorLink to="/work#play">
          <LogoDiamond className="fill-owlred hover:fill-owlred-dark"/>  
          </AnchorLink>
        </div>  
      </div>


    </section>    


    <section className="px-4 pb-8 mt-32 text-owlwhite">

      <div className="container">
      <h1 className="text-owlwhite display-2 text-6xl text-left mb-16 border-b pb-4" id="play">Play</h1>

        <div className="grid md:grid-cols-3 gap-8">

            <div className="md:col-span-2">
                <ClientCard 
                  class="snowspeeder"                  
                  client="play-snowspeeder"
                  clientname="Snowspeeder"
                  bg="#cdcdcd"
                />  
            </div>    

         {/*  <div className="md:col-span-1">
                <ClientCard 
                  class="stratos"                  
                  client="play-stratos"
                  clientname="Lancia Stratos"
                  bg="#cdcdcd"
                />  
              </div>   */}

            <div className="md:col-span-1">
                <ClientCard 
                  class="bramble"                  
                  client="play-bramble"
                  clientname="Bramble"
                  bg="#cdcdcd"
                />  
            </div>     

            <div className="md:col-span-1">
                <ClientCard 
                  class="cyber"                  
                  client="play-cyberspace"
                  clientname="Cyberspace"
                  bg="#cdcdcd"
                />  
            </div>                                        

        </div>
      </div>

    </section>   

  </Layout>
)

export default WorkPage
